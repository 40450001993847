import { render, staticRenderFns } from "./DataTableWeekday.vue?vue&type=template&id=54cd7d7a&scoped=true"
import script from "./DataTableWeekday.vue?vue&type=script&lang=js"
export * from "./DataTableWeekday.vue?vue&type=script&lang=js"
import style0 from "./DataTableWeekday.vue?vue&type=style&index=0&id=54cd7d7a&prod&lang=scss&scoped=true"
import style1 from "./DataTableWeekday.vue?vue&type=style&index=1&id=54cd7d7a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54cd7d7a",
  null
  
)

export default component.exports