<template>
  <div class="visitor-pricing__data table-sequence">
    <v-data-table
      class="data__table with-background"
      ref="table"
      :headers="headers"
      :items="data"
      :hide-default-header="isEmptyTable"
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:top>
        <v-toolbar class="v-data-table__status-bar" flat>
          <v-toolbar-title class="v-data-table__title">
            Sequence prices
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            :fullscreen="isMobile"
            width="80%"
            max-width="512px"
            @click:outside="close"
            v-if="!hideActions"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="pkmn-button pkmn-button--no-padding"
                text
                v-bind="attrs"
                v-on="on"
                color="primary"
              >
                <img src="@/assets/button-add.svg" />
              </v-btn>
            </template>
            <v-card class="form-dialog form-dialog--payout-id">
              <v-icon
                class="form-dialog__button form-dialog__button--close"
                @click="close"
              >
                mdi-close
              </v-icon>

              <h1 class="form-dialog__title">Set sequence price</h1>

              <p class="form-dialog__subtitle">
                Price will adapt based on settings for each step
              </p>

              <v-form ref="sequenceForm" class="data-table-sequence-form">
                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Step 1 time period</div>
                    <v-row class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          solo
                          flat
                          dense
                          background-color="#F6F6F6"
                          v-model="editedItem.time_period.value"
                          class="pkmn-input input-mr font-weight-bold"
                          type="number"
                          min="1"
                          :rules="[rules.onlyPositive]"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="editedItem.time_period.unit"
                          :items="timeDayUnitOptions"
                          solo
                          class="input-ml font-weight-bold"
                          flat
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Price</div>
                    <v-text-field
                      v-if="editedItem.ui_price_data"
                      solo
                      flat
                      dense
                      background-color="#F6F6F6"
                      v-model="editedItem.ui_price_data.price"
                      class="pkmn-input font-weight-bold"
                      type="number"
                      min="0"
                      :suffix="currentProductCurrencySymbol"
                      :rules="[rules.onlyPositive]"
                    />
                    <!-- v-text-field for migrated items with another structure at backend -->
                    <v-text-field
                      v-else
                      solo
                      flat
                      dense
                      background-color="#F6F6F6"
                      v-model="editedItem.price"
                      class="pkmn-input font-weight-bold"
                      type="number"
                      min="0"
                      :suffix="currentProductCurrencySymbol"
                      :rules="[rules.onlyPositive]"
                    />
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Price time unit</div>
                    <v-row class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          v-if="editedItem.ui_price_data"
                          solo
                          flat
                          dense
                          background-color="#F6F6F6"
                          v-model="editedItem.price_time_unit.value"
                          class="pkmn-input input-mr font-weight-bold"
                          type="number"
                          min="1"
                          :rules="[rules.onlyPositive]"
                        />
                        <!-- v-text-field for migrated items with another structure at backend -->
                        <v-text-field
                          v-else
                          solo
                          flat
                          dense
                          background-color="#F6F6F6"
                          v-model="editedItem.time_period.value"
                          class="pkmn-input input-mr font-weight-bold"
                          type="number"
                          min="1"
                          :rules="[rules.onlyPositive]"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-if="editedItem.ui_price_data"
                          v-model="editedItem.price_time_unit.unit"
                          :items="timeDayUnitOptions"
                          solo
                          class="input-ml font-weight-bold"
                          flat
                          background-color="#F6F6F6"
                          dense
                        />
                        <!-- v-text-field for migrated items with another structure at backend -->
                        <v-select
                          v-else
                          v-model="editedItem.time_period.unit"
                          :items="timeDayUnitOptions"
                          solo
                          class="input-ml font-weight-bold"
                          flat
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">
                      Charge increment (default: 1 sec)
                    </div>
                    <v-row class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          solo
                          flat
                          dense
                          background-color="#F6F6F6"
                          v-model="editedItem.charge_increment.value"
                          class="pkmn-input input-mr font-weight-bold"
                          type="number"
                          min="1"
                          :rules="[rules.onlyPositive]"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="editedItem.charge_increment.unit"
                          :items="timeDayUnitOptions"
                          solo
                          flat
                          class="input-ml font-weight-bold"
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-dialog__button-wrap">
                  <v-col>
                    <v-btn
                      class="pkmn-button pkmn-button--has-border"
                      color="primary"
                      @click="addPrice"
                    >
                      Confirm
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogDelete"
            :fullscreen="isMobile"
            width="80%"
            max-width="512px"
            @click:outside="closeDelete"
          >
            <v-card class="form-dialog form-dialog--payout-id">
              <v-icon
                class="form-dialog__button form-dialog__button--close"
                @click="closeDelete"
              >
                mdi-close
              </v-icon>

              <h1 class="form-dialog__title">Delete sequence price?</h1>

              <p class="form-dialog__subtitle">
                This action can't be undone. The next step will be moved higher
                in the order
              </p>

              <v-form>
                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Step 1 time period</div>
                    <v-row class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          solo
                          flat
                          dense
                          disabled
                          background-color="#F6F6F6"
                          v-model="editedItem.time_period.value"
                          class="pkmn-input pkmn-input--inline"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="editedItem.time_period.unit"
                          :items="timeDayUnitOptions"
                          solo
                          flat
                          disabled
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Price</div>
                    <v-text-field
                      solo
                      flat
                      dense
                      disabled
                      background-color="#F6F6F6"
                      v-model="editedItem.price"
                      class="pkmn-input"
                      type="number"
                      :suffix="currentProductCurrencySymbol"
                    />
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Price time unit</div>
                    <v-row class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          solo
                          flat
                          dense
                          disabled
                          background-color="#F6F6F6"
                          v-model="editedItem.price_time_unit.value"
                          class="pkmn-input pkmn-input--inline"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="editedItem.price_time_unit.unit"
                          :items="timeDayUnitOptions"
                          solo
                          flat
                          disabled
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">
                      Charge increment (default: 1 sec)
                    </div>
                    <v-row class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          solo
                          flat
                          dense
                          disabled
                          background-color="#F6F6F6"
                          v-model="editedItem.charge_increment.value"
                          class="pkmn-input pkmn-input--inline"
                          type="number"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="editedItem.charge_increment.unit"
                          :items="timeDayUnitOptions"
                          solo
                          flat
                          disabled
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-dialog__button-wrap pt-1">
                  <v-col cols="6" class="text-left">
                    <v-btn
                      class="pkmn-button"
                      outlined
                      color="danger"
                      @click="closeDelete"
                    >
                      Back
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      class="pkmn-button pkmn-button--delete pkmn-button--has-border"
                      color="danger"
                      @click="deleteItemConfirm"
                    >
                      Delete
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action" v-if="!hideActions">
          <div>
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="primary"
              @click="editSchedule(item)"
            >
              <img src="@/assets/button-edit.svg" />
            </v-btn>
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="deleteSchedule(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:item.time_period="{ item }">
        {{ timePeriodString(item) }}
      </template>
      <template v-slot:item.charge_increment="{ item }">
        {{ chargeIncrementString(item) }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ priceString(item) }}
      </template>
      <template v-slot:item.step_index="{ item }">
        {{ stepString(item) }}
      </template>
      <template v-if="isEmptyTable" v-slot:body="">
        <div></div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import rules from '@/helpers/validation'
import { mapGetters } from 'vuex'
import { TimeEnum } from '@/enums'

export default {
  name: 'DataTableSequence',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    currency: {
      type: String,
      default: '$'
    },
    hideActions: {
      type: Boolean
    }
  },
  data() {
    return {
      headers: [
        { text: 'Step', value: 'step_index', width: 120 },
        { text: 'Time period', value: 'time_period', width: 120 },
        { text: 'Price', value: 'price', width: 100 },
        { text: 'Charge increment', value: 'charge_increment', width: 150 },
        { text: '', value: 'id', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        price: 0,
        price_time_unit: {
          value: 1,
          unit: 'hour'
        },
        charge_increment: {
          value: 1,
          unit: 'second'
        },
        time_period: {
          value: 1,
          unit: 'hour'
        },
        ui_price_data: {
          price: 0,
          price_time_unit: {
            unit: 'hour',
            value: 1
          }
        }
      },
      defaultItem: {
        price: 0,
        price_time_unit: {
          value: 1,
          unit: 'hour'
        },
        charge_increment: {
          value: 1,
          unit: 'second'
        },
        time_period: {
          value: 1,
          unit: 'hour'
        },
        ui_price_data: {
          price: 0,
          price_time_unit: {
            unit: 'hour',
            value: 1
          }
        }
      },
      dialog: false,
      dialogDelete: false,
      mobileBreakpoint: 767, // screen width in pixel to switch to mobile view
      rules: rules
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  computed: {
    ...mapGetters(['timeDayUnitOptions', 'currentProductCurrencySymbol']),
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    isNewItem() {
      return this.editedIndex === -1
    },
    isEmptyTable() {
      return Object.keys(this.data).length === 0
    }
  },

  methods: {
    chargeIncrementString(item) {
      return `${item.charge_increment.value} ${
        TimeEnum[item.charge_increment.unit]
      }`
    },
    timePeriodString(item) {
      return `${item.time_period.value} ${TimeEnum[item.time_period.unit]}`
    },
    stepString(item) {
      if (item.step_index === 1) {
        return '1st'
      }
      return 'Next'
    },
    priceString(item) {
      return item.ui_price_data
        ? `${item.ui_price_data?.price} ${
            this.currentProductCurrencySymbol
          } / ${item.ui_price_data?.price_time_unit?.value} ${
            TimeEnum[item.ui_price_data?.price_time_unit?.unit]
          }`
        : `${item.price} ${this.currentProductCurrencySymbol} / ${
            item.time_period?.value
          } ${TimeEnum[item.time_period?.unit]}`
    },
    editSchedule(item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteSchedule(item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    addPrice() {
      if (this.$refs.sequenceForm.validate()) {
        const newItem = this.editedItem
        newItem.price = this.editedItem.ui_price_data?.price
        if (this.isNewItem) {
          this.$emit('submit', newItem)
        } else {
          this.$emit('update', newItem)
        }
        this.close()
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItemConfirm() {
      this.$emit('delete', this.editedItem.id)
      this.closeDelete()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.v-data-table__title {
  @include text-body;
  font-weight: 700;
  color: $text-color-dark;
}

.form-row {
  min-height: 60px;
  max-height: 70px;
}

.v-chip--active {
  @include text-label;
  border: 1px solid $color-parkman;
  color: $color-text-dark !important;
  background-color: $suffix-color !important;
}

.v-chip {
  @include text-label;
  border: 0px;
  color: $color-text-dark;
  background-color: $suffix-color;
}

.pkmn-button--delete {
  color: $color-white;
}

.schedule-table {
  background-color: $color-alt-background !important;
}

.table-sequence {
  width: 620px;
}
.form-dialog__button-wrap {
  margin-top: 24px !important;
}
</style>
<style lang="scss">
.data-table-sequence-form {
  .font-weight-bold input,
  .font-weight-bold .v-select__selection {
    font-weight: 900;
    line-height: 20px;
  }
  .input-mr {
    margin-right: 12px;
  }
  .input-ml {
    margin-left: 12px;
  }
}
</style>
