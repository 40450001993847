<template>
  <div class="location-product-settings pt-8">
    <v-dialog
      v-model="dialogDelete"
      :fullscreen="isMobile"
      width="80%"
      max-width="512px"
      @click:outside="closeDelete"
    >
      <v-card class="form-dialog form-dialog--payout-id">
        <v-icon
          class="form-dialog__button form-dialog__button--close"
          @click="closeDelete"
        >
          mdi-close
        </v-icon>

        <h1 class="form-dialog__title">
          Do you want to delete Visitor parking from {{ locationName }}
          {{ merchantName }}
        </h1>

        <p class="form-dialog__subtitle">This action can't be undone.</p>

        <v-form>
          <v-row class="form-dialog__button-wrap pt-1">
            <v-col cols="6" class="text-left">
              <v-btn
                class="pkmn-button"
                color="danger"
                outlined
                @click="closeDelete"
              >
                No, keep it
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                class="pkmn-button pkmn-button--delete color-white pkmn-button--has-border"
                color="danger"
                @click="deleteItemConfirm"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-form ref="settings-form" class="advanced-settings-form">
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Default VAT</div>
          <v-text-field
            v-model="formData.vat_percentage"
            class="form-font pkmn-input"
            type="number"
            background-color="#F6F6F6"
            solo
            flat
            dense
          >
            <template v-slot:append>
              <div class="append-icon">%</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Currency</div>
          <v-select
            v-model="formData.currency_id"
            class="form-font pkmn-input"
            background-color="#F6F6F6"
            item-value="id"
            item-text="name"
            :items="currencyTypes"
            :disabled="formData.is_currency_disabled"
            solo
            flat
            dense
          >
            <template v-slot:append>
              <img :src="dropdownNarrow" />
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Merchant kick back in</div>
          <v-text-field
            v-model="formData.kick_back_fee_percentage"
            class="form-font pkmn-input"
            type="number"
            background-color="#F6F6F6"
            solo
            flat
            dense
          >
            <template v-slot:append>
              <div class="append-icon">%</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Merchant kick back in</div>
          <v-text-field
            v-model="formData.kick_back_fee_fixed"
            class="form-font pkmn-input"
            type="number"
            background-color="#F6F6F6"
            solo
            flat
            dense
          >
            <template v-slot:append>
              <div class="append-icon">{{ currencyIcon }}</div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Parkman commission in</div>
          <v-text-field
            v-model="formData.commission_percentage"
            class="form-font pkmn-input"
            type="number"
            background-color="#F6F6F6"
            solo
            flat
            dense
          >
            <template v-slot:append>
              <div class="append-icon">%</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Parkman commission in</div>
          <v-text-field
            v-model="formData.commission_fixed"
            class="form-font pkmn-input"
            type="number"
            background-color="#F6F6F6"
            solo
            flat
            dense
          >
            <template v-slot:append>
              <div class="append-icon">{{ currencyIcon }}</div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="form-label">Merchant cost center (optional)</div>
          <v-text-field
            solo
            class="form-font pkmn-input error-border"
            v-model="formData.cost_center"
            flat
            background-color="#F6F6F6"
            dense
          >
            <template v-slot:append>
              <v-tooltip bottom color="rgba(0, 0, 0, 0)">
                <template v-slot:activator="{ on }">
                  <div class="info-icon" v-on="on">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0968 14.1088L10.8919 14.9465C10.2771 15.1892 9.78602 15.3739 9.42033 15.5008C9.05424 15.6282 8.62887 15.6916 8.14422 15.6916C7.39996 15.6916 6.82106 15.5092 6.40818 15.1471C5.9953 14.7835 5.78879 14.3228 5.78879 13.7639C5.78879 13.5475 5.80377 13.325 5.83493 13.0982C5.86634 12.8712 5.91629 12.6155 5.98465 12.3298L6.75297 9.61035C6.82132 9.34995 6.87942 9.10322 6.92595 8.8699C6.97314 8.63802 6.99588 8.42468 6.99588 8.23264C6.99588 7.88535 6.92398 7.64243 6.78083 7.50586C6.63769 7.36967 6.36454 7.30053 5.95902 7.30053C5.7604 7.30053 5.55626 7.33234 5.34817 7.39386C5.13917 7.45564 4.96053 7.51532 4.81055 7.57079L5.016 6.73241C5.51932 6.52748 6.00042 6.352 6.46062 6.20635C6.92083 6.06031 7.35566 5.98723 7.76696 5.98723C8.5061 5.98723 9.07646 6.16587 9.47698 6.52314C9.87751 6.88068 10.0777 7.34417 10.0777 7.91532C10.0777 8.03349 10.0646 8.24171 10.0363 8.53931C10.0087 8.83756 9.9573 9.11084 9.88237 9.35942L9.11734 12.0679C9.05464 12.2855 8.99824 12.5342 8.94921 12.814C8.89861 13.092 8.87442 13.3045 8.87442 13.4469C8.87442 13.8066 8.9546 14.0521 9.11537 14.1827C9.27731 14.3132 9.55624 14.3781 9.95243 14.3781C10.1384 14.3781 10.3501 14.3451 10.5852 14.2803C10.8197 14.2155 10.9907 14.1586 11.0968 14.1088ZM11.2908 2.73768C11.2908 3.20958 11.113 3.6126 10.7556 3.94399C10.3991 4.27655 9.96952 4.44297 9.46699 4.44297C8.96289 4.44297 8.53226 4.27655 8.17169 3.94399C7.81179 3.61247 7.63144 3.20958 7.63144 2.73768C7.63144 2.2667 7.81179 1.86302 8.17169 1.52743C8.5316 1.19237 8.96302 1.0249 9.46699 1.0249C9.96939 1.0249 10.3991 1.19276 10.7556 1.52743C11.1133 1.86302 11.2908 2.26683 11.2908 2.73768Z"
                        fill="#B9B9B9"
                      />
                    </svg>
                  </div>
                </template>
                <div class="tooltip-content">
                  <span
                    >This ID is used for merchant reporting and
                    book-keeping</span
                  >
                </div>
                <div class="tooltip-arrow"></div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div class="delete-button--container">
        <v-btn
          class="delete-button-content pl-0"
          text
          disabled
          color="danger"
          @click="dialogDelete = true"
        >
          Delete product
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import dropdownNarrow from '@/assets/dropdown_arrow.svg'
import { CurrencyTypesEnum } from '@/enums'

export default {
  props: {
    productSettings: {
      type: Object,
      default: null
    },
    locationName: {
      type: String,
      default: ''
    },
    merchantName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dropdownNarrow,
      mobileBreakpoint: 767,
      currencies: ['USD', 'EUR', 'GBP', 'DKK'],
      currencyTypes: CurrencyTypesEnum,
      formData: {
        currency_id: 1,
        vat_percentage: null,
        kick_back_fee_fixed: 0,
        kick_back_fee_percentage: 0,
        commission_fixed: 0,
        commission_percentage: 0,
        is_currency_disabled: false,
        cost_center: null
      },
      formDataCopy: '',
      dialogDelete: false
    }
  },
  methods: {
    deleteItemConfirm() {
      this.$emit('delete')
      this.closeDelete()
    },
    updateFormDataFromProductSettings(productSettings) {
      Object.assign(this.formData, {
        currency_id: productSettings.currency_id,
        vat_percentage: productSettings.vat_percentage === null || productSettings.vat_percentage === "" ? null : productSettings.vat_percentage.toString(),
        kick_back_fee_fixed: productSettings.kick_back_fee_fixed.toString(),
        kick_back_fee_percentage:
          productSettings.kick_back_fee_percentage.toString(),
        commission_fixed: productSettings.commission_fixed.toString(),
        commission_percentage: productSettings.commission_percentage.toString(),
        is_currency_disabled: productSettings.is_currency_disabled
      })
      this.formDataCopy = JSON.stringify(this.formData)
    },
    closeDelete() {
      this.dialogDelete = false
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    currencyIcon() {
      if (this.formData.currency_id) {
        return this.currencyTypes[this.formData.currency_id - 1].symbol
      }
      return null
    }
  },
  mounted() {
    if (this.productSettings) {
      this.updateFormDataFromProductSettings(this.productSettings)
    }
  },
  watch: {
    formData: {
      handler(newValue) {
        const validated = newValue.currency_id !== null
        this.$emit('validated', validated)
        if (Object.values(this.productSettings).length > 0) {
          const changed = JSON.stringify(this.formData) !== this.formDataCopy
          this.$emit('changed', changed)
        }
        if (validated) {
          this.$emit('formData', newValue)
        }
      },
      deep: true
    },
    productSettings(newValue) {
      if (newValue) {
        this.updateFormDataFromProductSettings(newValue)
        this.$emit('changed', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.advanced-settings-form {
  width: 339px;

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}
.form-font .v-input__slot {
  --padding: 10px;
  background: #eaeaea !important;
}
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}
.append-icon {
  font-size: 16px !important;
  color: rgba(31, 31, 31, 0.5);
}

.tooltip-content {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  width: 168px;
  background-color: white;
  color: black;
  padding: 8px 16px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.add-user-table {
  padding-top: 32px !important;
}

.delete-button--container {
  width: 314px;
  height: 46px;
  padding-top: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;

  .delete-button-content {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    line-height: 22.4px;
    word-wrap: break-word;
    text-transform: capitalize;
    letter-spacing: normal;
  }
}

.info-icon {
  cursor: pointer;
}

.info-icon:hover path {
  fill: #37bd01;
}
</style>
<style lang="scss">
.color-white {
  color: #fff !important;
}
</style>
