<template>
  <div class="visitor-pricing__data table-weekday">
    <v-data-table
      class="data__table with-background"
      ref="table"
      :headers="headers"
      :items="data"
      :hide-default-header="isEmptyTable"
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:top>
        <v-toolbar class="v-data-table__status-bar" flat>
          <v-toolbar-title class="v-data-table__title">
            Weekday prices
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            :fullscreen="isMobile"
            width="80%"
            max-width="512px"
            @click:outside="close"
            v-if="!hideActions"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="pkmn-button pkmn-button--no-padding"
                text
                v-bind="attrs"
                v-on="on"
                color="primary"
              >
                <img src="@/assets/button-add.svg" />
              </v-btn>
            </template>
            <v-card class="form-dialog form-dialog--payout-id">
              <v-icon
                class="form-dialog__button form-dialog__button--close"
                @click="close"
              >
                mdi-close
              </v-icon>

              <h1 class="form-dialog__title">Set weekday price</h1>

              <v-form ref="weekdayForm" class="mt-3 weekly-price-form">
                <v-row class="form-row input-row">
                  <v-col cols="6">
                    <div class="text-input-label">From</div>
                    <v-text-field
                      class="form-font font-weight-bold"
                      v-model="editedItem.tod_start"
                      solo
                      flat
                      dense
                      background-color="#F6F6F6"
                      :rules="[rules.isValidTime]"
                    />
                  </v-col>

                  <v-col cols="6">
                    <div class="text-input-label">Until</div>
                    <v-text-field
                      class="form-font font-weight-bold"
                      v-model="editedItem.tod_end"
                      solo
                      flat
                      dense
                      background-color="#F6F6F6"
                      :rules="[rules.isValidTime]"
                    />
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Price</div>
                    <v-text-field
                      solo
                      flat
                      dense
                      background-color="#F6F6F6"
                      v-model="editedItem.price"
                      class="pkmn-input font-weight-bold"
                      type="number"
                      :suffix="currentProductCurrencySymbol"
                      min="0"
                      :rules="[rules.onlyPositive]"
                    />
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Price time unit</div>
                    <v-row cols="12" class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          solo
                          flat
                          dense
                          background-color="#F6F6F6"
                          v-model="editedItem.price_time_unit.value"
                          class="pkmn-input input-mr font-weight-bold"
                          type="number"
                          min="1"
                          :rules="[rules.onlyPositive]"
                        />
                      </v-col>
                      <v-col cols="6" class="pl-3">
                        <v-select
                          v-model="editedItem.price_time_unit.unit"
                          :items="timeUnitOptions"
                          class="font-weight-bold"
                          solo
                          flat
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">
                      Charge increment (default: 1 sec)
                    </div>
                    <v-row cols="12" class="no-gutters">
                      <v-col cols="6">
                        <v-text-field
                          solo
                          flat
                          dense
                          background-color="#F6F6F6"
                          v-model="editedItem.charge_increment.value"
                          class="pkmn-input input-mr font-weight-bold"
                          type="number"
                          min="1"
                          :rules="[rules.onlyPositive]"
                        />
                      </v-col>
                      <v-col cols="6" class="pl-3">
                        <v-select
                          class="font-weight-bold"
                          v-model="editedItem.charge_increment.unit"
                          :items="timeUnitOptions"
                          solo
                          flat
                          background-color="#F6F6F6"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col>
                    <div class="text-input-label">Set weekday price</div>

                    <v-chip-group
                      v-model="weekdaySelection"
                      outlined
                      multiple
                      class="week-price-chip-group"
                      active-class="v-chip--active"
                    >
                      <v-chip
                        class="v-chip"
                        v-for="(item, i) in weekday"
                        :key="i"
                      >
                        {{ item }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>

                <v-row class="form-dialog__button-wrap">
                  <v-col cols="12">
                    <v-btn
                      class="pkmn-button pkmn-button--has-border"
                      color="primary"
                      @click="addWeekdayPrice"
                    >
                      Confirm
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogDelete"
            :fullscreen="isMobile"
            width="80%"
            max-width="512px"
            @click:outside="closeDelete"
          >
            <v-card class="form-dialog form-dialog--payout-id">
              <v-icon
                class="form-dialog__button form-dialog__button--close"
                @click="closeDelete"
              >
                mdi-close
              </v-icon>

              <h1 class="form-dialog__title">Delete ?</h1>

              <p class="form-dialog__subtitle">This action can't be undone.</p>

              <v-form class="weekly-price-form-delete">
                <v-row class="form-row input-row">
                  <v-col cols="6" style="height: 60px">
                    <div class="text-input-label">From</div>
                    <v-text-field
                      solo
                      flat
                      dense
                      disabled
                      background-color="#F6F6F6"
                      v-model="editedItem.tod_start"
                      class="pkmn-input"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" style="height: 60px">
                    <div class="text-input-label">Until</div>
                    <v-text-field
                      solo
                      flat
                      dense
                      disabled
                      background-color="#F6F6F6"
                      v-model="editedItem.tod_end"
                      class="pkmn-input"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col cols="6" style="height: 60px">
                    <div class="text-input-label">Price</div>
                    <v-text-field
                      solo
                      flat
                      dense
                      disabled
                      background-color="#F6F6F6"
                      v-model="editedItem.price"
                      class="pkmn-input"
                      type="number"
                      :suffix="currentProductCurrencySymbol"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col cols="6" style="height: 60px">
                    <div class="text-input-label together">Price time unit</div>
                    <v-text-field
                      solo
                      flat
                      dense
                      disabled
                      background-color="#F6F6F6"
                      v-model="editedItem.price_time_unit.value"
                      class="pkmn-input"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" style="height: 60px">
                    <div
                      class="text-input-label text-input-label__without-text"
                    ></div>
                    <v-select
                      v-model="editedItem.price_time_unit.unit"
                      :items="timeUnitOptions"
                      solo
                      flat
                      background-color="#F6F6F6"
                      dense
                      disabled
                    />
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col cols="6" style="height: 60px">
                    <div class="text-input-label">
                      Charge increment (default: 1 sec)
                    </div>
                    <v-text-field
                      solo
                      flat
                      dense
                      disabled
                      background-color="#F6F6F6"
                      v-model="editedItem.charge_increment.value"
                      class="pkmn-input"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="6" style="height: 60px">
                    <div
                      class="text-input-label text-input-label__without-text"
                    ></div>
                    <v-select
                      v-model="editedItem.charge_increment.unit"
                      :items="timeUnitOptions"
                      solo
                      flat
                      background-color="#F6F6F6"
                      dense
                      disabled
                    />
                  </v-col>
                </v-row>

                <v-row class="form-row input-row">
                  <v-col cols="12" style="height: 60px">
                    <div class="text-input-label">Set weekday price</div>

                    <v-chip-group
                      v-model="weekdaySelection"
                      outlined
                      multiple
                      class="week-price-chip-group"
                      active-class="v-chip--active"
                    >
                      <v-chip
                        class="v-chip"
                        v-for="(item, i) in weekday"
                        :key="i"
                        disabled
                      >
                        {{ item }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>

                <v-row class="form-dialog__button-wrap">
                  <v-col cols="6" class="text-left">
                    <v-btn
                      class="pkmn-button"
                      outlined
                      color="danger"
                      @click="closeDelete"
                    >
                      Back
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      class="pkmn-button pkmn-button--delete pkmn-button--has-border"
                      color="danger"
                      @click="deleteItemConfirm"
                    >
                      Delete
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.tod_start="{ item }">
        {{ formatedStartTime(item.tod_start) }}
      </template>
      <template v-slot:item.tod_end="{ item }">
        {{ formatedEndTime(item.tod_start, item.tod_end) }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ priceString(item) }}
      </template>
      <template v-slot:item.charge_increment="{ item }">
        {{ chargeIncrementString(item) }}
      </template>
      <template v-slot:item.weekday="{ item }">
        <span class="text-no-wrap">{{ weekdaysString(item) }}</span>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action" v-if="!hideActions">
          <div>
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="primary"
              @click="editSchedule(item)"
            >
              <img src="@/assets/button-edit.svg" />
            </v-btn>
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="deleteSchedule(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
          </div>
        </div>
      </template>

      <template v-if="isEmptyTable" v-slot:body="">
        <div></div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dropdownNarrow from '@/assets/dropdown_arrow.svg'
import moment from 'moment'
import rules from '@/helpers/validation'
import { mapGetters } from 'vuex'
import { TimeEnum } from '@/enums'

export default {
  name: 'DataTableWeekday',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    hideActions: {
      type: Boolean
    }
  },
  data() {
    return {
      headers: [
        { text: 'From', value: 'tod_start', width: 120 },
        { text: 'To', value: 'tod_end', width: 120 },
        { text: 'Price', value: 'price', width: 100 },
        { text: 'Charge increment', value: 'charge_increment', width: 150 },
        { text: 'Weekday', value: 'weekday', width: 150 },
        { text: '', value: 'id', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        tod_start: '00:00',
        tod_end: '00:00',
        price: 1,
        weekday: '',
        charge_increment: {
          value: 1,
          unit: 'second'
        },
        price_time_unit: {
          value: 1,
          unit: 'hour'
        }
      },
      defaultItem: {
        tod_start: '00:00',
        tod_end: '00:00',
        price: 1,
        weekday: '',
        charge_increment: {
          value: 1,
          unit: 'second'
        },
        price_time_unit: {
          value: 1,
          unit: 'hour'
        }
      },
      dialog: false,
      dialogDelete: false,
      weekday: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      weekdaySelection: [],
      mobileBreakpoint: 767, // screen width in pixel to switch to mobile view
      dropdownNarrow,
      menuVisibleFromTime: false,
      menuVisibleUntilTime: false,
      rules: rules
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  computed: {
    ...mapGetters(['timeUnitOptions', 'currentProductCurrencySymbol']),
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    isNewItem() {
      return this.editedIndex === -1
    },
    isEmptyTable() {
      return Object.keys(this.data).length === 0
    }
  },

  methods: {
    weekdaysString(item) {
      const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      if (item.weekday && Array.isArray(item.weekday)) {
        return item.weekday.map((dayNum) => weekdays[dayNum - 1]).join(', ')
      } else {
        return ''
      }
    },
    priceString(item) {
      if (item.price_time_unit.value === 1) {
        return `${item.price} ${this.currentProductCurrencySymbol}/ ${
          TimeEnum[item.price_time_unit.unit]
        }`
      }
      return `${item.price} ${this.currentProductCurrencySymbol}/ ${
        item.price_time_unit.value
      }${TimeEnum[item.price_time_unit.unit]}`
    },
    chargeIncrementString(item) {
      return `${item.charge_increment.value} ${
        TimeEnum[item.charge_increment.unit]
      }`
    },
    editSchedule(item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.weekdaySelection = item.weekday.map((day) => day - 1)
      this.dialog = true
    },
    deleteSchedule(item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.weekdaySelection = item.weekday.map((day) => day - 1)
      this.dialogDelete = true
    },
    addWeekdayPrice() {
      if (this.$refs.weekdayForm.validate()) {
        const daysOfWeek = {
          mon: this.weekdaySelection.includes(0),
          tue: this.weekdaySelection.includes(1),
          wed: this.weekdaySelection.includes(2),
          thu: this.weekdaySelection.includes(3),
          fri: this.weekdaySelection.includes(4),
          sat: this.weekdaySelection.includes(5),
          sun: this.weekdaySelection.includes(6)
        }
        this.editedItem.days_of_week = daysOfWeek
        if (this.isNewItem) {
          this.$emit('submit', this.editedItem)
        } else {
          this.$emit('update', this.editedItem)
        }
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.weekdaySelection = []
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.weekdaySelection = []
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItemConfirm() {
      const idNumbers = { idNumbers: this.editedItem.ids }
      this.$emit('delete', idNumbers)
      this.closeDelete()
    },
    handleIconFromTimeClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleFromTime = !this.menuVisibleFromTime
    },
    handleIconUntilTimeClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleUntilTime = !this.menuVisibleUntilTime
    },
    formatedStartTime(time) {
      return moment(time, 'HH:mm:ss').format('HH:mm')
    },
    formatedEndTime(startTime, endTime) {
      const momentStartTime = moment(startTime, 'HH:mm:ss')
      const momentEndTime = moment(endTime, 'HH:mm:ss')
      return momentEndTime.isBefore(momentStartTime) ||
        momentEndTime.isSame(momentStartTime)
        ? `${momentEndTime.format('HH:mm')}(+1d)`
        : momentEndTime.format('HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.v-data-table__title {
  @include text-body;
  font-weight: 700;
  color: $text-color-dark;
}

.form-row {
  min-height: 60px;
  max-height: 70px;
}

.v-chip {
  @include text-label;
  border: 0px solid;
  color: $color-text-dark;
  background-color: $suffix-color;
  width: 32px;
  height: 32px;
  &--active {
    @include text-label;
    border: 1px solid $color-parkman;
    color: $color-text-dark !important;
    background-color: rgba(55, 189, 1, 0.1) !important;
    &::before {
      background: transparent;
    }
    &:hover {
      background-color: rgba(55, 189, 1, 0.2) !important;
    }
  }
}

.pkmn-button--delete {
  color: $color-white;
}

.schedule-table {
  background-color: $color-alt-background !important;
}

.table-weekday {
  width: 770px;
}

.together {
  padding-bottom: 0px;
}

.text-input-label {
  &__without-text {
    height: 12px;
  }
}
</style>
<style lang="scss">
.week-price-chip-group {
  .v-slide-group__content {
    padding-top: 0;
    justify-content: space-between;
  }
}
.weekly-price-form,
.weekly-price-form-delete {
  .font-weight-bold input,
  .font-weight-bold .v-select__selection {
    font-weight: 900;
    line-height: 20px;
  }
  .input-mr {
    margin-right: 12px;
  }
  .form-dialog__button-wrap {
    margin-top: 36px !important;
  }
  .v-chip {
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
