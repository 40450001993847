<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog form-dialog--set-pricing-schedule">
      <img
        src="@/assets/trash-grey.png"
        v-if="
          isPriceDraft &&
          !isDeleting &&
          !isExpiredSchedule &&
          auth.isParkmanAdmin()
        "
        class="form-dialog__button form-dialog__button--second"
        @click="showDeleteForm"
      />

      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">
        {{ this.formTitle }}
      </h1>
      <span
        class="form-dialog__subtitle"
        v-if="!isPriceActive && editItem === null"
      >
        {{ this.formMessage }}
      </span>

      <v-form class="pricing-form">
        <v-row class="group-components">
          <v-row class="input-row">
            <v-col cols="12">
              <div class="text-input-label">Schedule type</div>
              <v-select
                v-model="formData.priority"
                :items="scheduleTypeOps"
                solo
                flat
                background-color="#F6F6F6"
                dense
                :disabled="disableField"
              />
            </v-col>
          </v-row>

          <v-row class="input-row">
            <v-col cols="6">
              <div class="text-input-label">Valid from</div>
              <v-menu
                v-model="menuVisibleValidFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :disabled="disableField"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="form-font"
                    v-model="visibleValidFrom"
                    readonly
                    solo
                    flat
                    dense
                    background-color="#F6F6F6"
                    v-on="on"
                    :disabled="disableField"
                  >
                    <template v-slot:append>
                      <div @click="handleIconValidFromClick" class="clickable">
                        <img :src="dropdownNarrow" />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  no-title
                  color="primary"
                  v-model="visibleValidFromDate"
                  @input="menuVisibleValidFrom = false"
                  :disabled="disableField"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <div class="text-input-label">Start time</div>
              <v-text-field
                class="form-font"
                v-model="validStartTime"
                solo
                flat
                dense
                background-color="#F6F6F6"
                :disabled="disableField"
                :rules="[rules.isValidTime]"
              />
            </v-col>
          </v-row>

          <v-row class="input-row">
            <v-col cols="6">
              <div class="text-input-label">Valid till</div>
              <v-menu
                v-model="menuVisibleValidUntil"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                class="menu-style"
                :disabled="
                  isDeleting || isExpiredSchedule || !auth.isParkmanAdmin()
                "
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="form-font"
                    v-model="visibleValidUntil"
                    readonly
                    solo
                    v-on="on"
                    flat
                    dense
                    background-color="#F6F6F6"
                    :disabled="
                      isDeleting || isExpiredSchedule || !auth.isParkmanAdmin()
                    "
                  >
                    <template v-slot:append>
                      <div
                        @click="handleIconValidStartTimeClick"
                        class="clickable"
                      >
                        <img :src="dropdownNarrow" />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-if="menuVisibleValidUntil"
                  v-model="visibleValidUntilDate"
                  color="primary"
                  no-title
                  :disabled="validUntilFurtherNotice || isDeleting"
                  @input="menuVisibleValidUntil = false"
                >
                </v-date-picker>
                <v-row class="menu-style valid-to-switcher">
                  <v-col>
                    <v-switch
                      color="primary"
                      v-model="validUntilFurtherNotice"
                      class="switch-wrapper"
                    >
                      <template v-slot:label>
                        <span class="input__label">Until further notice</span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <div class="text-input-label">End time</div>
              <v-text-field
                class="form-font"
                v-model="validEndTime"
                solo
                flat
                dense
                background-color="#F6F6F6"
                :disabled="
                  validUntilFurtherNotice ||
                  isDeleting ||
                  isExpiredSchedule ||
                  !auth.isParkmanAdmin()
                "
                :rules="[rules.isValidTime]"
              />
            </v-col>
          </v-row>
        </v-row>

        <v-row class="group-components">
          <v-row class="input-row">
            <v-col cols="6">
              <div class="text-input-label">Occurs from</div>
              <v-menu
                v-model="menuVisibleOccursFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :disabled="disableField"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="form-font"
                    v-model="occursFromDateString"
                    readonly
                    solo
                    flat
                    dense
                    background-color="#F6F6F6"
                    v-on="on"
                    :disabled="disableField"
                  >
                    <template v-slot:append>
                      <div @click="handleIconOccursFromClick" class="clickable">
                        <img :src="dropdownNarrow" />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="visibleOccursFromDate"
                  ref="occursFromDatePicker"
                  no-title
                  color="primary"
                  :min="occursMinDate"
                  :max="occursMaxDate"
                  :disabled="disableField"
                  :header-date-format="customFormatFromHeader"
                  @input="menuVisibleOccursFrom = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <div class="text-input-label">Start time</div>
              <v-text-field
                class="form-font"
                v-model="formData.occurs_start_time"
                solo
                flat
                dense
                background-color="#F6F6F6"
                :disabled="disableField"
                :rules="[rules.isValidTime]"
              />
            </v-col>
          </v-row>

          <v-row class="input-row">
            <v-col cols="6">
              <div class="text-input-label">Occurs till</div>
              <v-menu
                v-model="menuVisibleOccursUntil"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                class="menu-style"
                :disabled="disableField"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    class="form-font"
                    v-model="occursUntilDateString"
                    readonly
                    solo
                    v-on="on"
                    flat
                    dense
                    background-color="#F6F6F6"
                    :disabled="disableField"
                  >
                    <template v-slot:append>
                      <div
                        @click="handleIconOccursUntilClick"
                        class="clickable"
                      >
                        <img :src="dropdownNarrow" />
                      </div>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="visibleOccursUntilDate"
                  ref="occursUntilDatePicker"
                  color="primary"
                  no-title
                  :min="occursMinDate"
                  :max="occursMaxDate"
                  :disabled="disableField"
                  :header-date-format="customFormatUntilHeader"
                  @input="menuVisibleOccursUntil = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <div class="text-input-label">End time</div>
              <v-text-field
                class="form-font"
                v-model="formData.occurs_end_time"
                solo
                flat
                dense
                background-color="#F6F6F6"
                :disabled="disableField"
                :rules="[rules.isValidTime]"
              />
            </v-col>
          </v-row>
        </v-row>

        <v-row class="group-components">
          <v-row class="input-row">
            <v-col>
              <div class="text-input-label">
                Fixed amount added to each parking
              </div>
              <v-text-field
                ref="amountAdded"
                solo
                flat
                dense
                background-color="#F6F6F6"
                v-model="formData.fixed_fee_amount"
                class="pkmn-input"
                type="number"
                min="0"
                :suffix="currentProductCurrencySymbol"
                :disabled="disableField"
                :rules="[rules.onlyPositive]"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="input-row">
            <v-col>
              <div class="text-input-label">Max purchase time</div>
              <v-row class="no-gutters">
                <v-col cols="6">
                  <v-text-field
                    solo
                    flat
                    dense
                    background-color="#F6F6F6"
                    v-model="formData.max_purchase_time.value"
                    class="pkmn-input max-purchase-value"
                    type="number"
                    :disabled="disableField"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="formData.max_purchase_time.unit"
                    :items="timeDayUnitOptions"
                    solo
                    flat
                    class="max-purchase-unit"
                    background-color="#F6F6F6"
                    dense
                    :disabled="disableField"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>

        <v-row dense v-if="errorMessage">
          <v-col cols="12" class="text-right">
            <p class="pkmn-form__error" v-html="errorMessage"></p>
          </v-col>
        </v-row>
        <v-row
          class="form-dialog__button-wrap"
          v-if="isDeleting && !isExpiredSchedule && auth.isParkmanAdmin()"
        >
          <v-col cols="6" class="text-left">
            <v-btn
              class="pkmn-button"
              outlined
              color="danger"
              @click="closeDelete"
            >
              Back
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              class="pkmn-button pkmn-button--delete pkmn-button--has-border"
              color="danger"
              @click="deleteItemConfirm"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          class="form-dialog__button-wrap"
          v-else-if="!isExpiredSchedule && auth.isParkmanAdmin()"
        >
          <v-col>
            <v-btn
              v-if="!isPriceSaved"
              class="pkmn-button"
              color="primary"
              @click="submit"
            >
              Save draft
            </v-btn>
            <v-btn v-else class="pkmn-button" color="primary" @click="submit">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from '@/auth'
import dropdownNarrow from '@/assets/dropdown_arrow.svg'
import rules from '@/helpers/validation'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { CurrencyTypesEnum } from '@/enums'
import i18n from '@/plugins/i18n'

export default {
  name: 'FormSetPricingSchedule',
  props: {
    currencyId: {
      type: Number,
      default: 0
    },
    editItem: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      auth: auth,
      alwaysOpen: true,
      formData: {
        priority: 'base',
        valid_from: null,
        valid_to: null,
        occurs_from: null,
        occurs_start_time: null,
        occurs_to: null,
        occurs_end_time: null,
        fixed_fee_amount: 0,
        max_purchase_time: {
          value: 0,
          unit: 'hour'
        }
      },
      dropdownNarrow,
      visibleValidFrom: '',
      menuVisibleValidFrom: false,
      visibleValidFromDate: null,
      validStartTime: null,
      validEndTime: null,
      visibleValidUntil: 'Further notice',
      menuVisibleValidUntil: false,
      visibleValidUntilDate: null,
      validUntilFurtherNotice: true,

      visibleOccursFrom: '',
      menuVisibleOccursFrom: false,
      visibleOccursFromDate: null,
      occursFromDateString: null,
      visibleOccursUntil: '',
      menuVisibleOccursUntil: false,
      visibleOccursUntilDate: null,
      occursUntilDateString: null,

      rules: rules,
      errorMessage: null,
      currencyTypes: CurrencyTypesEnum,
      isDeleting: false,
      isExpiredSchedule: false
    }
  },
  computed: {
    ...mapGetters(['timeDayUnitOptions', 'currentProductCurrencySymbol']),
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    scheduleTypeOps() {
      return [
        {
          text: 'Base',
          value: 'base'
        },
        {
          text: 'Priority',
          value: 'priority'
        }
      ]
    },
    isPriceSaved() {
      return !!this.formData?.id
    },
    isPriceDraft() {
      return this.formData.status_id === 1
    },
    isPriceActive() {
      return this.formData?.status_id === 3
    },
    formTitle() {
      if (this.isDeleting) {
        return this.$t('delete_schedule_title')
      } else if (this.isExpiredSchedule) {
        return this.$t('past_schedule_title')
      } else {
        return this.$t('add_schedule_title')
      }
    },
    formMessage() {
      if (this.isDeleting) {
        return this.$t('delete_schedule_message')
      } else {
        return this.$t('add_schedule_message')
      }
    },
    disableField() {
      return (
        this.isPriceActive ||
        this.isDeleting ||
        this.isExpiredSchedule ||
        !auth.isParkmanAdmin()
      )
    },
    occursMinDate() {
      return moment().startOf('year').format('YYYY-MM-DD')
    },
    occursMaxDate() {
      return moment().endOf('year').format('YYYY-MM-DD')
    }
  },

  watch: {
    validStartTime(newValue) {
      if (newValue) {
        const date = moment(this.visibleValidFromDate)
        this.formData.valid_from =
          date.format('YYYY-MM-DD') + ' ' + newValue + ':00'
      }
    },
    validEndTime(newValue) {
      if (newValue) {
        const date = moment(this.visibleValidUntilDate)
        this.formData.valid_to =
          date.format('YYYY-MM-DD') + ' ' + newValue + ':59'
      }
    },
    visibleValidFromDate(newValue) {
      if (newValue) {
        const date = moment(newValue)
        this.visibleValidFrom = date.format('DD.MM.YYYY')
        if (this.validStartTime == null) {
          this.validStartTime = '00:00'
        }
        this.formData.valid_from =
          date.format('YYYY-MM-DD') + ' ' + this.validStartTime + ':00'
      }
    },
    visibleValidUntilDate(newValue) {
      if (newValue) {
        const date = moment(newValue)
        if (this.validUntilFurtherNotice) {
          this.visibleValidUntil = 'Further notice'
          this.formData.valid_to = null
          // clear the end time
          this.validEndTime = null
        } else {
          this.visibleValidUntil = date.format('DD.MM.YYYY')
          if (this.validEndTime == null) {
            this.validEndTime = '23:59'
          }
          this.formData.valid_to =
            date.format('YYYY-MM-DD') + ' ' + this.validEndTime + ':59'
        }
      }
    },
    validUntilFurtherNotice(newValue) {
      if (newValue) {
        this.visibleValidUntil = 'Further notice'
        this.formData.valid_to = null

        // clear the end time
        this.validEndTime = '23:59'
      } else {
        const actualValidDate = moment(
          this.editItem?.schedule[0].valid_to || new Date()
        )
        this.visibleValidUntilDate = actualValidDate.format('YYYY-MM-DD')
        this.formData.valid_to =
          actualValidDate.format('YYYY-MM-DD') + ' ' + this.validEndTime + ':59'
        this.visibleValidUntil = actualValidDate.format('DD.MM.YYYY')
      }
    },
    visibleOccursFromDate(newValue) {
      if (newValue) {
        const date = moment(newValue)
        this.visibleOccursFrom = date.format('DD.MM.YYYY')
        this.formData.occurs_from = date.format('YYYY-MM-DD')
        this.occursFromDateString = date.format('DD.MM')
      }
    },
    visibleOccursUntilDate(newValue) {
      if (newValue) {
        const date = moment(newValue)
        this.visibleOccursUntil = date.format('DD.MM.YYYY')
        this.formData.occurs_to = date.format('YYYY-MM-DD')
        this.occursUntilDateString = date.format('DD.MM')
      }
    },
    editItem: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.mapToFormData(newValue)
        }
      }
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    closeDelete() {
      this.isDeleting = false
    },
    deleteItemConfirm() {
      this.$emit('delete', this.editItem?.schedule[0].id)
    },
    submit() {
      if (this.formData.valid_to && moment(this.formData.valid_to).isBefore()) {
        this.$store.dispatch('setSystemMessage', {
          message: `${i18n.t('not_allowed_in_the_past')}`,
          type: 'error'
        })
        return
      }
      if (this.$refs.amountAdded.validate()) {
        this.formSubmitted = true
        this.submitForm()
      }
    },
    showDeleteForm() {
      this.isDeleting = true
    },
    async submitForm() {
      this.errorMessage = null
      const occursFromTime = this.formData.occurs_start_time
        ? `${this.formData.occurs_start_time}:00`
        : '00:00:00'
      const occursToTime = this.formData.occurs_end_time
        ? `${this.formData.occurs_end_time}:59`
        : '23:59:59'
      if (this.formData.occurs_from) {
        this.formData.occurs_from =
          this.formData.occurs_from.split(' ')[0] + ' ' + occursFromTime
      }
      if (this.formData.occurs_to) {
        this.formData.occurs_to =
          this.formData.occurs_to.split(' ')[0] + ' ' + occursToTime
      }
      if (this.formData.id) {
        await this.$emit('update', this.formData)
      } else {
        await this.$emit('submit', this.formData)
      }
    },
    handleIconValidFromClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleValidFrom = !this.menuVisibleValidFrom
    },
    handleIconValidUntilClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleValidUntil = !this.menuVisibleValidUntil
    },
    handleIconOccursFromClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleOccursFrom = !this.menuVisibleOccursFrom
    },
    handleIconOccursUntilClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleOccursUntil = !this.menuVisibleOccursUntil
    },
    handleIconValidStartTimeClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleValidStartTime = !this.menuVisibleValidStartTime
    },
    secondsToUnit(seconds) {
      if (seconds % (24 * 60 * 60) === 0) {
        return { value: seconds / (24 * 60 * 60), unit: 'day' }
      } else if (seconds % (60 * 60) === 0) {
        return { value: seconds / (60 * 60), unit: 'hour' }
      } else if (seconds % 60 === 0) {
        return { value: seconds / 60, unit: 'minute' }
      } else {
        return { value: seconds, unit: 'second' }
      }
    },
    mapToFormData(apiData) {
      // Destructuring data for easier usage
      const { schedule, fixedFees, restrictions } = apiData
      // Extracting the first (and likely only) schedule object
      const [firstSchedule] = schedule

      var price = 0
      if (fixedFees !== null) {
        price = fixedFees.price
      }
      const convertedForm = {
        fixed_fee_amount: price,
        max_purchase_time: this.secondsToUnit(
          restrictions.max_purchase_time_seconds
        ),
        priority:
          typeof firstSchedule.priority === 'number'
            ? this.scheduleTypeOps[firstSchedule.priority]?.value
            : firstSchedule.priority,
        product_id: firstSchedule.product_id,
        status_id: firstSchedule.status_id,
        location_id: firstSchedule.location_id,
        id: firstSchedule.id
      }
      this.formData = Object.assign({}, convertedForm)

      if (firstSchedule.valid_from) {
        const validFrom = new Date(firstSchedule.valid_from)
        const date = moment(validFrom)
        this.validStartTime = date.format('HH:mm')
        this.visibleValidFromDate = date.format('YYYY-MM-DD')
      }

      if (firstSchedule.occurs_from) {
        const occursFrom = new Date(firstSchedule.occurs_from)
        const date = moment(occursFrom)
        this.visibleOccursFromDate = date.format('YYYY-MM-DD')
        this.formData.occurs_start_time = date.format('HH:mm')
      }

      if (firstSchedule.occurs_to) {
        const occursTo = new Date(firstSchedule.occurs_to)
        const date = moment(occursTo)
        this.visibleOccursUntilDate = date.format('YYYY-MM-DD')
        this.formData.occurs_end_time = date.format('HH:mm')
      }
      if (firstSchedule.valid_to) {
        this.validUntilFurtherNotice = false
        const validTo = new Date(firstSchedule.valid_to)
        const date = moment(validTo)
        this.validEndTime = date.format('HH:mm')
        this.visibleValidUntilDate = date.format('YYYY-MM-DD')
      } else {
        this.validUntilFurtherNotice = true
      }
    },
    customFormatFromHeader(isoDate) {
      this.$nextTick(() => {
        this.hideCalentarYears(this.$refs.occursFromDatePicker.$el)
      })
      return isoDate.length === 7
        ? moment(isoDate, 'YYYY-MM').format('MMMM')
        : ''
    },
    customFormatUntilHeader(isoDate) {
      this.$nextTick(() => {
        this.hideCalentarYears(this.$refs.occursUntilDatePicker.$el)
      })
      return isoDate.length === 7
        ? moment(isoDate, 'YYYY-MM').format('MMMM')
        : ''
    },
    hideCalentarYears(targetNodeRef) {
      const targetNode = targetNodeRef.querySelector('.v-picker__body')

      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true }

      // Callback function to execute when mutations are observed
      const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const headerBtn = targetNode.querySelector(
              '.primary--text > button'
            )
            if (headerBtn?.innerHTML?.length === 0) {
              headerBtn.disabled = true
              headerBtn.style.cursor = 'default'
              targetNode
                .querySelectorAll('.v-date-picker-header > button')
                .forEach((x) => {
                  x.disabled = true
                  x.style.cursor = 'default'
                  x.style.display = 'none'
                })
            } else {
              targetNode
                .querySelectorAll('.v-date-picker-header > button')
                .forEach((x) => {
                  x.disabled = false
                  x.style.cursor = 'pointer'
                  x.style.display = 'block'
                })
            }
          }
        }
      }

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback)

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config)
    }
  },
  mounted() {
    if (this.editItem !== null) {
      this.isExpiredSchedule =
        this.editItem?.schedule[0].valid_to &&
        moment(this.editItem?.schedule[0].valid_to).isBefore(moment(new Date()))
      this.mapToFormData(this.editItem)
    } else {
      const date = moment()
      this.visibleValidFromDate = date.format('YYYY-MM-DD')
      this.validStartTime = date.add(1, 'hour').startOf('hour').format('HH:mm')
    }
  }
}
</script>

<style lang="scss">
.valid-to-switcher {
  margin: 0 !important;
}
.pricing-form {
  input,
  .v-select__selection {
    font-weight: 500 !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 140%;
    color: var(--web-text-main, #333);
  }
}
</style>
<style lang="scss" scoped>
@import '@/style/common';

.form-font .v-input__slot {
  --padding: 10px;
  background: #eaeaea !important;
}

.v-select .v-select__selections {
  height: 48px;
}
.v-menu__content {
  background-color: #fff;
  .v-picker__title {
    font-size: 16px;
    font-weight: 500;
  }
}
.pkmn-button--delete {
  color: $color-white;
}

.group-components {
  margin-left: 0;
  margin-right: 0;
  padding-top: 20px;
}

.max-purchase-value {
  margin-right: 12px;
}
.max-purchase-unit {
  margin-left: 12px;
}
</style>
