<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>
      <h1 class="form-dialog__title">
            Delete HUB connection?
      </h1>
      <p class="form-dialog__subtitle">
            Visitor parking transactions are no longer going to be sent to the HUB for this location
      </p>
      <v-form class="mt-8">
            <v-row class="form-dialog__button-wrap pt-1">
        <v-col cols="12" class="text-right">
          <v-btn
            class="pkmn-button pkmn-button--delete pkmn-button--has-border"
            color="danger"
            @click="deleteConfirm"
          >
            Delete
          </v-btn>
        </v-col>
      </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import * as config from '@/config/config'


export default {
  name: 'FormDeleteHubPopup',
  props: {
    data: Object
  },
  data() {
    return {
      alwaysOpen: true,
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= config.SMALL_SCREEN_BREAKPOINT
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    deleteConfirm() {
      this.$emit('delete')
    }
  },
  mounted() {
    // if user press Escape, close the dialog and clear all data
    // ONLY works if a component is NOT attacted to the view as a default (use v-if to decide whether a component is attacted or not)
    // Otherwise, all 'close' listener of components existing in a view will be notified
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.title-size {
  width: 302px !important;
}
.blackTr {
  color: var(--web-text-main, #333);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  height: 56px;
  vertical-align: middle;
  cursor: pointer;
}
.bottom-border {
  width: 426px;
  border-bottom: 1px solid #e5e5e5;
}

.arrow {
  width: 24px;
  align-content: right;
  text-align: right;

  &--has-bottom-border {
    border-bottom: 1px solid #e5e5e5;
  }
}

.pkmn-button--delete {
  color: $color-white;
}

</style>
