<template>
  <div>
    <v-tabs class="tabs" v-model="currentItem" left slider-color="white">
      <v-tab
        class="smaller-tab"
        :class="{ 'pl-0': idx === 0 }"
        v-for="(item, idx) in notExpiredPrices"
        :key="item.id"
      >
        <div class="tab-pricing-wrapper">
          <span>{{ checkExpired(item) }}{{ formatScheduleDate(item) }}</span>
          <v-btn
            v-if="isActiveTab(idx) && priceDetails"
            class="pkmn-button--no-padding"
            @click="editSchedule"
            text
          >
            <v-icon class="primary--text pa-0" right> mdi-pencil </v-icon>
          </v-btn>
          <v-tooltip
            color="rgba(0, 0, 0, 0)"
            v-if="isDraft(item)"
            bottom
            content-class=""
          >
            <template v-slot:activator="{ on }">
              <span class="warning-dot-outlined ml-2" v-on="on"></span>
            </template>
            <div class="tooltip-content">
              <span
                >Draft. Activate when all pricing rules are set. After that only
                the end date will be editable</span
              >
            </div>
            <div class="tooltip-arrow"></div>
          </v-tooltip>
        </div>
      </v-tab>

      <v-spacer></v-spacer>
      <v-menu v-if="expiredPrices.length" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            class="pkmn-button-small btn-past-schedule"
            v-bind="attrs"
            v-on="on"
          >
            Check past schedules
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </template>

        <v-list class="grey lighten-3">
          <v-list-item
            v-for="item in expiredPrices"
            :key="item.id"
            @click="openExpiredSchedule(item)"
          >
            {{ formatScheduleDate(item) }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>

    <v-tabs-items class="tab-pricing-items" v-model="currentItem">
      <v-tab-item
        class="tab-item"
        v-for="item in notExpiredPrices"
        :key="item.id"
      >
        <v-divider class="mb-4" />
        <tab-schedule
          ref="tabSchedule"
          :currencyId="currencyId"
          :weekday="currentWeekday"
          :periodic="currentPeriodic"
          :sequence="currentSequence"
          :isActive="isActive(item)"
          :isExpired="isExpired(item)"
          :closeDataTableWeekdayPopup="closeDataTableWeekdayPopup"
          @addWeekDay="addWeekDay"
          @updateWeekDay="updateWeekDay"
          @deleteWeekDay="deleteWeekDay"
          @addSequence="addSequence"
          @updateSequence="updateSequence"
          @deleteSequence="deleteSequence"
          @addPeriodic="addPeriodic"
          @updatePeriodic="updatePeriodic"
          @deletePeriodic="deletePeriodic"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TabSchedule from '@/components/Locations/AddVisitorParking/Tab/TabPricingSchedule.vue'
import merchantHelpers from '@/helpers/merchant'
import moment from 'moment'

export default {
  props: {
    currencyId: {
      type: Number,
      default: 0
    },
    prices: {
      type: Array,
      default: () => []
    }
  },
  name: 'TabPricing',

  components: {
    TabSchedule
  },

  data: () => ({
    currentItem: 0,
    items: [],
    currentWeekday: [],
    currentSequence: [],
    currentPeriodic: [],
    priceDetails: null,
    expiredPrices: [],
    notExpiredPrices: [],
    closeDataTableWeekdayPopup: true
  }),
  methods: {
    async editSchedule() {
      this.$emit('edit', this.priceDetails)
    },
    async openExpiredSchedule(schedule) {
      this.notExpiredPrices = this.notExpiredPrices.filter(
        (schedule) => !schedule.expired
      )
      this.notExpiredPrices.push(schedule)
      this.currentItem = this.notExpiredPrices.length - 1
      await this.getPricingDetails(schedule.id)
    },
    isActiveTab(tabIdx) {
      return this.currentItem === tabIdx
    },
    isInReview(item) {
      return item.status_id === 2
    },
    isDraft(item) {
      return item.status_id === 1
    },
    isActive(item) {
      return item.status_id === 3
    },
    isExpired(item) {
      return item.expired
    },
    formatScheduleDate(item) {
      const getDDMMFormat = (dateStr) => {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
        const date = new Date(dateStr)
        return `${String(date.getDate()).padStart(2, '0')} ${
          months[date.getMonth()]
        }`
      }

      const getYYYYFormat = (dateStr) => {
        const date = new Date(dateStr)
        return date.getFullYear()
      }

      const occursFromDDMM = getDDMMFormat(item.occurs_from)
      const occursToDDMM = getDDMMFormat(item.occurs_to)
      const validFromYYYY = getYYYYFormat(item.valid_from)
      const validToYYYY = item.valid_to ? getYYYYFormat(item.valid_to) : null
      let result = ''
      if (item.priority === 1) {
        result += 'Priority '
      }
      result += `${occursFromDDMM} - ${occursToDDMM} (${validFromYYYY}`
      if (validToYYYY) {
        result += validFromYYYY !== validToYYYY ? `-${validToYYYY}` : ''
      } else {
        result += ' ➔'
      }
      result += ')'

      return result
    },
    async getPricingDetails(scheduleId) {
      const price = await merchantHelpers.getPricingScheduleDetails(
        this.$route.params.merchantId,
        scheduleId
      )
      this.priceDetails = price
      this.currentWeekday = this.groupWeekdays(price)
      this.currentPeriodic = this.mapPeriodic(price)
      this.currentSequence = this.mapSequence(price)
    },
    secondsToUnit(seconds) {
      if (seconds % (24 * 60 * 60) === 0) {
        return { value: seconds / (24 * 60 * 60), unit: 'day' }
      } else if (seconds % (60 * 60) === 0) {
        return { value: seconds / (60 * 60), unit: 'hour' }
      } else if (seconds % 60 === 0) {
        return { value: seconds / 60, unit: 'minute' }
      } else {
        return { value: seconds, unit: 'second' }
      }
    },
    groupWeekdays(price) {
      if (!price?.weekdayPaidTimes) {
        return []
      }
      const weekdaysGrouped = price.weekdayPaidTimes.reduce((acc, curr) => {
        curr.todStart = curr.todStart.slice(0, -3)
        curr.todEnd = curr.todEnd.slice(0, -3)
        const existing = acc.find(
          (item) =>
            item.tod_start === curr.todStart &&
            item.tod_end === curr.todEnd &&
            item.price === curr.basePrice.price
        )

        if (existing) {
          existing.weekday.push(curr.dowStart)
          existing.ids.push(curr.id)
        } else {
          acc.push({
            tod_start: curr.todStart,
            tod_end: curr.todEnd,
            price: curr.basePrice.price,
            weekday: [curr.dowStart],
            ids: [curr.id],
            charge_increment: this.secondsToUnit(
              curr.basePrice.charge_increment_seconds
            ),
            price_time_unit: this.secondsToUnit(
              curr.basePrice.price_time_unit_seconds
            )
          })
        }
        // part for migrated items with another structure at backend
        if (curr.dowEnd - curr.dowStart > 1) {
          const daysList = []
          for (let i = curr.dowStart; i <= curr.dowEnd; i++) {
            daysList.push(i)
          }
          acc[acc.length - 1].weekday = daysList
        }

        return acc
      }, [])

      return weekdaysGrouped
    },
    mapPeriodic(price) {
      if (!price?.periodicPriceItems) {
        return []
      }
      return price.periodicPriceItems.items.map((item) => {
        return {
          id: item.id,
          time_period: this.secondsToUnit(item.time_amount_seconds),
          price: item.price
        }
      })
    },
    mapSequence(price) {
      if (!price?.sequencePriceSteps) {
        return []
      }
      return price.sequencePriceSteps.steps.map((step) => {
        return {
          id: step.id,
          step_index: step.step_index,
          price: step.ui_price_data ? step.ui_price_data.price : step.price,
          price_time_unit: step.ui_price_data
            ? step.ui_price_data.price_time_unit
            : this.secondsToUnit(step.time_unit_seconds),
          charge_increment: this.secondsToUnit(step.charge_increment_seconds),
          time_period: this.secondsToUnit(step.time_unit_seconds),
          ...step
        }
      })
    },
    async addWeekDay(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem]?.id
      const weekdayPrice = await merchantHelpers.createWeekdayPrices(
        this.$route.params.merchantId,
        scheduleId,
        item
      )
      if (weekdayPrice) {
        await this.getPricingDetails(scheduleId)
        this.closeDataTableWeekdayPopup = !this.closeDataTableWeekdayPopup
        await this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('added_confirm_message', { object: 'Weekday price' })
        })
        await this.$store.dispatch('setSystemMessage', {
          type: 'warning',
          message: this.$t('remember_to_update_admin_v2_settings')
        })
      }
    },
    async deleteWeekDay(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      await merchantHelpers.deleteWeekdayPrices(
        this.$route.params.merchantId,
        scheduleId,
        item
      )
      await this.getPricingDetails(scheduleId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'delete',
        message: this.$t('price_is_removed', { object: 'Weekday price' })
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    async addSequence(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      await merchantHelpers.createSequencePrices(
        this.$route.params.merchantId,
        scheduleId,
        item
      )
      await this.getPricingDetails(scheduleId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('added_confirm_message', { object: 'Sequence price' })
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    async updateWeekDay(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      const idNumbers = { idNumbers: item.ids }
      await merchantHelpers.deleteWeekdayPrices(
        this.$route.params.merchantId,
        scheduleId,
        idNumbers
      )
      const weekdayPrice = await merchantHelpers.createWeekdayPrices(
        this.$route.params.merchantId,
        scheduleId,
        item
      )
      if (weekdayPrice) {
        await this.getPricingDetails(scheduleId)
        this.closeDataTableWeekdayPopup = !this.closeDataTableWeekdayPopup
        await this.$store.dispatch('setSystemMessage', {
          type: 'success',
          message: this.$t('changes_are_saved')
        })
        await this.$store.dispatch('setSystemMessage', {
          type: 'warning',
          message: this.$t('remember_to_update_admin_v2_settings')
        })
      }
    },
    async updateSequence(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      await merchantHelpers.updateSequencePrices(
        this.$route.params.merchantId,
        scheduleId,
        item.id,
        item
      )
      await this.getPricingDetails(scheduleId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('changes_are_saved')
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    async deleteSequence(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      await merchantHelpers.deleteSequencePrices(
        this.$route.params.merchantId,
        scheduleId,
        item
      )
      await this.getPricingDetails(scheduleId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'delete',
        message: this.$t('price_is_removed', { object: 'Sequence price' })
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    async addPeriodic(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      await merchantHelpers.createPeriodicPrices(
        this.$route.params.merchantId,
        scheduleId,
        item
      )
      await this.getPricingDetails(scheduleId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('added_confirm_message', { object: 'Periodic price' })
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    async updatePeriodic(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      await merchantHelpers.updatePeriodicPrices(
        this.$route.params.merchantId,
        scheduleId,
        item.id,
        item
      )
      await this.getPricingDetails(scheduleId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('changes_are_saved')
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    async deletePeriodic(item) {
      const scheduleId = this.notExpiredPrices[this.currentItem].id
      await merchantHelpers.deletePeriodicPrices(
        this.$route.params.merchantId,
        scheduleId,
        item
      )
      await this.getPricingDetails(scheduleId)
      await this.$store.dispatch('setSystemMessage', {
        type: 'delete',
        message: this.$t('price_is_removed', { object: 'Periodic price' })
      })
      await this.$store.dispatch('setSystemMessage', {
        type: 'warning',
        message: this.$t('remember_to_update_admin_v2_settings')
      })
    },
    setPrices() {
      this.expiredPrices = []
      this.notExpiredPrices = []
      this.prices.forEach((price) => {
        price.valid_to && moment(price.valid_to).isBefore(moment(new Date()))
          ? this.expiredPrices.push({ expired: true, ...price })
          : this.notExpiredPrices.push({ ...price })
        if (price.id === this.priceDetails?.schedule[0]?.id) {
          this.priceDetails = { ...this.priceDetails, schedule: [price] }
        }
      })
      if (!this.prices.length) {
        this.priceDetails = {}
      }
    },
    checkExpired(item) {
      return item.expired ? 'Past ' : ''
    }
  },
  watch: {
    currentItem(newValue) {
      if (newValue !== undefined) {
        if (!this.notExpiredPrices[newValue]?.expired) {
          this.notExpiredPrices = this.notExpiredPrices.filter(
            (price) => !price.expired
          )
        }
        this.$emit('priceItemChanged', newValue)
        if (this.notExpiredPrices.length >= newValue) {
          this.currentWeekday = []
          this.currentPeriodic = []
          this.currentSequence = []
          this.getPricingDetails(this.notExpiredPrices[newValue].id)
        }
      }
    },
    prices() {
      this.setPrices()
      if (
        this.notExpiredPrices.length &&
        this.notExpiredPrices.length >= this.currentItem
      ) {
        this.getPricingDetails(this.notExpiredPrices[this.currentItem].id)
      }
    },
    priceDetails(newValue) {
      this.$emit('pricigScheduleList', newValue)
    }
  },
  async mounted() {
    if (this.prices.length) {
      this.setPrices()
      if (
        this.notExpiredPrices.length &&
        this.notExpiredPrices.length >= this.currentItem
      ) {
        await this.getPricingDetails(this.notExpiredPrices[this.currentItem].id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';

.tabs {
  margin-left: 0;
  margin-top: 0;
  max-height: 32px;
}

.tab {
  font-size: 10px;
}

.btn-past-schedule {
  @include text-label;
  padding-right: 0 !important;
  margin-top: 4px;
  text-transform: unset !important;
}

.tab-item {
  overflow-y: auto;
  transition: false;
}

.has-review {
  color: $color-warning;
}

.tooltip-content {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  width: 168px;
  background-color: white;
  color: black;
  padding: 8px 16px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
</style>
<style lang="scss">
.v-tabs {
  .btn-past-schedule {
    display: flex;
    align-items: flex-end;
    padding-bottom: 4px !important;
    span {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.tab-pricing-wrapper {
  display: flex;
  align-items: center;
}
.tab-pricing-items {
  .tab-item {
    overflow-y: visible;
  }
}
</style>
