<template>
  <v-dialog
    v-model="showPopup"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog form-dialog--unsaved">
      <h1 class="form-dialog__title">
        Is {{ currentProductCurrencySymbol }} correct currency for this product?
      </h1>

      <p class="form-dialog__subtitle">
        For now currency for a product can be set only before the 1st schedule
        is activated.
      </p>

      <v-row class="form-dialog__button-wrap">
        <v-col cols="6">
          <v-btn class="pkmn-button" outlined color="primary" @click="close">
            No
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="pkmn-button pkmn-button--confirm pkmn-button--has-border"
            color="primary"
            @click="approve"
          >
            Yes
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CurrencyApprovePopup',
  props: {
    showPopup: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['currentProductCurrencySymbol'])
  },
  methods: {
    close() {
      this.$emit('close')
    },
    approve() {
      this.$emit('approve')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
</style>
