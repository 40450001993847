<template>
  <div class="add-weekday-table">
    <v-row>
      <v-col>
        <data-table-weekday
          ref="dataTableWeekday"
          :data="weekday"
          :hideActions="isActive || isExpired || !auth.isParkmanAdmin()"
          @update="updateWeekdayPrice"
          @delete="deleteWeekdayPrice"
          @submit="addWeekdayPrice"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <data-table-sequence
          :data="sequence"
          :hideActions="isActive || isExpired || !auth.isParkmanAdmin()"
          @submit="addSequencePrice"
          @update="updateSequencePrice"
          @delete="deleteSequencePrice"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <data-table-periodic
          :data="periodic"
          :hideActions="isActive || isExpired || !auth.isParkmanAdmin()"
          @submit="addPeriodicPrice"
          @update="updatePeriodic"
          @delete="deletePeriodic"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import auth from '@/auth'
import DataTableWeekday from '@/components/Locations/AddVisitorParking/DataTable/DataTableWeekday'
import DataTableSequence from '@/components/Locations/AddVisitorParking/DataTable/DataTableSequence'
import DataTablePeriodic from '@/components/Locations/AddVisitorParking/DataTable/DataTablePeriodic'

import { mapGetters } from 'vuex'

export default {
  name: 'PricingSchedule',
  components: {
    DataTableWeekday,
    DataTableSequence,
    DataTablePeriodic
  },
  props: {
    weekday: {
      type: Array
    },
    sequence: {
      type: Array
    },
    periodic: {
      type: Array
    },
    isActive: {
      type: Boolean
    },
    isExpired: {
      type: Boolean
    },
    closeDataTableWeekdayPopup: {
      type: Boolean
    }
  },
  data() {
    return {
      auth: auth
    }
  },
  watch: {
    closeDataTableWeekdayPopup() {
      this.$refs.dataTableWeekday.close()
    }
  },
  computed: {
    ...mapGetters(['currentLocation']),
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    updateWeekdayPrice(item) {
      this.$emit('updateWeekDay', item)
    },
    deleteWeekdayPrice(item) {
      this.$emit('deleteWeekDay', item)
    },
    addWeekdayPrice(item) {
      this.$emit('addWeekDay', item)
    },
    addSequencePrice(item) {
      item.step_index = this.sequence.length + 1
      this.$emit('addSequence', item)
    },
    updateSequencePrice(item) {
      this.$emit('updateSequence', item)
    },
    deleteSequencePrice(item) {
      this.$emit('deleteSequence', item)
    },
    addPeriodicPrice(item) {
      this.$emit('addPeriodic', item)
    },
    updatePeriodic(item) {
      this.$emit('updatePeriodic', item)
    },
    deletePeriodic(item) {
      this.$emit('deletePeriodic', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.add-weekday-table {
  padding-top: 12px !important;
}
</style>

<style lang="scss">
.clickable {
  img {
    width: 24px;
    height: 24px;
  }
}
.v-text-field.v-text-field--enclosed.v-text-field--single-line
  .v-text-field__suffix {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  padding-left: 0;
}
</style>
